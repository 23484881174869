.app {
  margin: 0 auto;
  padding: 1rem;

  font-family: var(--font-family-sans);
  font-size: var(--font-size-medium);
  color: var(--color-text);

  max-width: 600px;

  .add {
    position: fixed;
    left: 50%;
    bottom: 1.4rem;

    border-radius: 100%;
    margin-left: -1.7rem;
    height: 3.4rem;
    width: 3.4rem;

    background: var(--color-negative-light);
    color: white;

    .icon {
      padding: 0;
    }
  }
}
