*,
*::before,
*::after { box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: var(--color-background);

  &.no-scroll {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}

select,
input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'] {
  @include reset-button;
  position: relative;
  color: black;
  background-color: white;
  padding: 0.5rem;
  line-height: 1.25rem;
  width: 100%;
  border: 0;
  border: 1px solid var(--color-border);

  &:focus {
    outline: 1px solid var(--color-focus);
  }
}

b {
  font-weight: 600;
}
