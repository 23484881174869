.item {
  display: flex;

  margin-bottom: 3px;
  padding: 5px 7px;
  border-radius: 3px;

  background: black;
  color: white;

  &:not(.is-active) {
    background: white;
    color: var(--color-text-light);
    opacity: 0.3;
  }

  .button, .icon {
    height: 2.2rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__handle {
    cursor: move;
    margin-left: 0.5rem;
    margin-right: 1rem;
  }

  &__name {
    @include no-select;
    pointer-events: none;
    flex: 1;
    align-self: center;
    margin-bottom: 1px;
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
